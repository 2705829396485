<template>
  <div class="main-container" v-if="returns">


    <!--    <Breadcrumb :items="items" v-bind:title="movie.title" :style="{'background-image':'url(' + this.baseImageUrl + movie.backdrop_path + ')'}" />-->

    <div class="container py-5">
      <div class="row">
        <div class="col-12">
          <h1 class="mb-5 border-bottom pb-4">{{ returns.kategori.name }}</h1>
        </div>
        <MovieComponent v-for="item in returns.movies.results" v-bind:key="item.id" :item="item"></MovieComponent>


        <div class="col-12">
          <ul v-if="returns.movies.page && returns.movies.total_pages" class="pagination">
            <li v-if="returns.movies.page > 3" class="page-item">
              <a class="page-link" @click="setPage(1)">1</a>
            </li>

            <li v-if="returns.movies.page > 4" class="page-item disabled">
              <a class="page-link disabled">...</a>
            </li>

            <li v-if="returns.movies.page-2 > 0" class="page-item">
              <a class="page-link" @click="setPage(returns.movies.page-2)">{{ returns.movies.page-2 }}</a>
            </li>

            <li v-if="returns.movies.page-1 > 0" class="page-item">
              <a class="page-link" @click="setPage(returns.movies.page-1)">{{ returns.movies.page-1 }}</a>
            </li>

            <li v-if="returns.movies.total_pages > 1" class="page-item active">
              <a class="page-link active">{{ returns.movies.page }}</a>
            </li>

            <li v-if="returns.movies.page+1 <= returns.movies.total_pages && returns.movies.page+1 != returns.movies.total_pages" class="page-item">
              <a class="page-link" @click="setPage(returns.movies.page+1)">{{ returns.movies.page+1 }}</a>
            </li>

            <li v-if="returns.movies.page+2 <= returns.movies.total_pages && returns.movies.page+2 != returns.movies.total_pages" class="page-item">
              <a class="page-link" @click="setPage(returns.movies.page+2)">{{ returns.movies.page+2 }}</a>
            </li>

            <li v-if="returns.movies.page+4 <= returns.movies.total_pages" class="page-item disabled">
              <a class="page-link disabled">...</a>
            </li>

            <li v-if="returns.movies.page < returns.movies.total_pages" class="page-item">
              <a class="page-link" @click="setPage(returns.movies.total_pages)">{{ returns.movies.total_pages }}</a>
            </li>


<!--            <li v-if="returns.movies.page > 2" class="page-item first" :class="{'disabled': returns.movies.page === 1}">
              <a class="page-link" @click="setPage(1)">1</a>
            </li>
            <li v-if="returns.movies.page > 1" class="page-item previous" :class="{'disabled': returns.movies.page === 1}">
              <a class="page-link" @click="setPage(returns.movies.page-1)">{{ returns.movies.page-1 }}</a>
            </li>
            <li v-for="page in returns.movies.total_pages" :key="page" class="page-item page-number" :class="{'active': returns.movies.page === page}">
              <a class="page-link" @click="setPage(page)">{{page}}</a>
            </li>
            <li v-if="returns.movies.page != returns.movies.total_pages" class="page-item next" :class="{'disabled': returns.movies.page === returns.movies.total_pages}">
              <a class="page-link" @click="setPage(returns.movies.page+1)">{{returns.movies.page+1}}</a>
            </li>
            <li class="page-item last" :class="{'disabled': returns.movies.page === returns.movies.total_pages}">
              <a class="page-link" @click="setPage(returns.movies.total_pages)">{{returns.movies.total_pages}}</a>
            </li>-->
          </ul>

        </div>
      </div>
    </div>


    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import data from '../data/project.json'
import axios from "axios";
import router from "@/router";
import MovieLogos from "@/components/MovieLogos";
import Genre from "@/components/movies/Genre";
import MovieComponent from "@/components/MovieComponent";
import { Fancybox } from "@fancyapps/ui";

export default {
  components: {
    MovieLogos,
    Genre,
    MovieComponent
  },
  data() {
    return {
      data,
      returns: null,
      slug: this.$route.params.id,
      pager:null,
      pages:null,
      items: [
        {
          text: 'Home',
          to: "/"
        },
        {
          text: 'Movie Details',
          active: true
        }
      ]
    }
  },
  metaInfo: {
    title: 'Castro - Movie Details',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    }
  },
  mounted() {

    axios
        .get("https://cd.tufangokmenler.com/api.php?key=genre&slug=" + this.slug)
        .then(response => (this.returns = response.data))
        .catch((error) => {
          if (error.response.status === 404) {
            // this.$router.push('/404')
          }
        });
  },
  methods: {
    setPage(page){
      axios
          .get("https://cd.tufangokmenler.com/api.php?key=genre&slug=" + this.slug + "&page=" + page)
          .then(response => (this.returns = response.data))
          .catch((error) => {
            if (error.response.status === 404) {
              // this.$router.push('/404')
            }
          });
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    }
  }
}
</script>
<style scoped>
@import "~@fancyapps/ui/dist/fancybox.css";
</style>
